<template>
  <!-- 合作伙伴 -->
  <Layout class="page-partner">
    <div class="main-partner">
      <template v-for="(item, index) in list">
        <div class="md-partner-1 text-center" :key="index" v-ani.fade-up>
          <div class="base-width c-card">
            <div class="title s26 font-medium">{{ item.title }}</div>
            <div class="desc s16 color-9" v-if="item.subheading">{{ item.subheading }}</div>
            <LogoList :list='item.list'></LogoList>
          </div>
        </div>
      </template>
    </div>
  </Layout>
</template>

<script>
import { PartnerApi } from "@/request/api/partner"
export default {
  data() {
    return {
      list: []
    };
  },
  mounted(){
    this.getPartnerList()
  },
  methods: {
    getPartnerList(){
      PartnerApi().then(res => {
        if(res.status == 200){
          this.list = res.data;
          this.list = this.list.map(item => {
            item.children = item.list.map(childItem => {
              childItem.name = childItem.title;
              childItem.src = childItem.logo_image;
              delete childItem.logo_image;
              delete childItem.title;
              return childItem;
            })
            return item;
          })
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
// 在main.scss
</style>